(function($) {

    //******************************************************************************
    //* Countup
    //******************************************************************************
    cad_bim_countup = {
        init: function () {
            const _this = this;
            const targetElements = document.querySelectorAll('.nd-countup .number');

            // Define the callback for the IntersectionObserver
            const handleIntersection = (entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const value = entry.target.dataset.value;
                        if (value) {
                            _this.animateValue(entry.target, 0, value, 5000);            
                        }

                        // Optionally, stop observing if you only need the action once
                        observer.unobserve(entry.target);
                    }
                });
            };

            // Create an IntersectionObserver instance
            const observer = new IntersectionObserver(handleIntersection, {
                root: null, // Use the viewport as the root
                rootMargin: '0px', // No margin
                threshold: 0.2 // Trigger when 10% of the element is visible
            });

            targetElements.forEach(element => observer.observe(element));
        },

        animateValue: function (obj, start, end, duration) {
            let startTimestamp = null;
            const step = (timestamp) => {
                if (!startTimestamp) { startTimestamp = timestamp; }

                const progress = Math.min((timestamp - startTimestamp) / duration, 1);
                obj.innerHTML = Math.floor(progress * (end - start) + start);

                if (progress < 1) {
                    window.requestAnimationFrame(step);
                }
            };
            window.requestAnimationFrame(step);
        }    
    };

})(jQuery);    
